@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;500;700&display=swap');
@import './styles/variables.css';

html,
body {
  background: var(--accent);
  color: white;
  font-family: var(--font-family);
  font-weight: var(--text-regular);
  text-rendering: optimizeLegibility;
  font-size: 100%;
}

a {
  color: inherit;
}
