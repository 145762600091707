.card {
  color: black;
  background: white;
  box-shadow: var(--shadow-1);
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.header {
  font-weight: var(--text-bold);
  margin: 0 0 0.5rem;
}

.content {
  flex-grow: 1;
  white-space: pre-line;
}

.label {
  font-size: 0.5em;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  right: 0;
  background: var(--green);
  color: white;
  padding: 0.3rem;
  font-weight: var(--text-semibold);
  letter-spacing: 0.2em;
}

.aside {
  flex-grow: 1;
}
