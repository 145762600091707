.ParkingCounter {
  text-align: right;
  font-size: 1.5rem;
  font-weight: var(--text-semibold);
}

.legend {
  font-size: 0.8rem;
  opacity: 0.8;
  font-weight: var(--text-regular);
  margin-top: 0.3em;
}

.green {
  color: var(--green);
}

.red {
  color: var(--red);
}

.orange {
  color: var(--orange);
}
