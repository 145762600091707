.container {
  padding: 1rem;
  padding-top: 0;
  max-width: 1000px;
  min-width: 300px;
  margin: auto;
  min-height: calc(100vh - 4rem);
}

.footer {
  font-size: 0.85em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  max-width: 1000px;
  min-width: 300px;
  margin: auto;
}

footer a {
  text-decoration: none;
}

.footerIcon {
  height: 1em;
}
.footerLang {
  font-size: 0.85em;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  background: unset;
  color: inherit;
  cursor: pointer;
  text-align: center;
}
.footerLang:focus {
  outline: none;
}
.footerGlobe {
  height: 1.5em;
  margin-right: 0.25em;
}

.backButton {
  color: white;
  text-decoration: none;
}

.backbuttonFiller {
  height: 1.6em;
}

.backButtonIcon {
  display: inline-block;
  width: 1em;
  height: 1em;
  padding: 0.3em;
  margin-right: 0.2em;
  border-radius: 50%;
  transition: all 300ms ease-in;
}

.backButton:hover .backButtonIcon {
  background: rgba(255, 255, 255, 0.2);
}
