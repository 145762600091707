.card p {
  margin-top: 0.7em;
}

.card h4 {
  margin-bottom: 0.7em;
}

.card td,
.card th {
  padding: 0 1rem;
  text-align: left;
  white-space: nowrap;
}

.mapContainer {
  height: 50vh;
  width: 100%;
  min-width: 300px;
  border-radius: 10px;
  margin-top: 1rem;
}
