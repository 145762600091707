.ParkingCard {
  margin: 1rem 0;
}

.buttonContainer {
  margin: 2rem 0 0;
}

.button,
.button {
  background: var(--accent);
  padding: 0.5em 0.8em;
  color: white;
  cursor: pointer;
  font-weight: var(--text-bold);
  font-size: var(--text-md);
  border: none;
  border-radius: 5px;
  box-shadow: var(--shadow-1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  outline: none;
  text-decoration: none;
}

.button:hover {
  box-shadow: var(--shadow-2);
}
