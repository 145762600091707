:root {
  --blue: #009de0;
  --green: #41ba36;
  --red: #f20f36;
  --orange: #f95706;
  --accent: var(--blue);
  --font-family: 'Fira Sans', 'sans-serif';
  --shadow-1: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  --shadow-2: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  --shadow-3: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  --shadow-4: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  --shadow-5: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  --text-regular: 300;
  --text-semibold: 500;
  --text-bold: 700;
  --text-sm: 0.5rem;
  --text-md: 1rem;
  --text-lg: 1.5rem;
}
